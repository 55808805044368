import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useContext,
  Fragment,
} from "react";
import moment from "moment";
import { CTX } from "../../utils/ContextStore";
import { useLazyQuery } from "@apollo/client";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Collapse,
  LinearProgress,
  Alert,
  tableCellClasses,
  Paper,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import { Add, Delete, Refresh, Search } from "@mui/icons-material";
import { useCurrentHeight } from "../../utils/helpers";

import GET_USERS from "src/queries/GET_USERS";

import AddUserModal from "./AddUserModal";
import BulkDeleteModal from "./BulkDeleteModal";
import EditBoxComponent from "./EditBoxComponent";
import DatasetPicker from "../PanelComponents/DatasetPicker";

const privOptionList: Array<string> = [
  "ADMIN",
  "CUSTOMER_ADMIN",
  "USER_ADMIN",
  "TAGGING",
  "GENDER_ANALYSIS",
  "IMAGE_TAGGING",
  "QUEUE",
  "CONCEPT_MANAGEMENT",
  "CONCEPT_SUGGESTIONS",
  "PRODUCTION_INSIGHTS",
  "TIMES",
  "CREATE_WIKIDATA",
  "API_DOCS",
  "QUALITY_EVALUATOR",
].sort();

const apiOptionList: Array<string> = [
  "TAGGING_API",
  "CM_API",
  "PI_API",
  "REC_API",
].sort();

interface IData {
  username: string;
  dataset: string;
  privsString: string;
  demo_user: string;
  confirmed: string;
  confirmed_at: string;
  apiCalls: number;
  expires: string;
  created: string;
  last_login: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const arrayLowerCase = array.map((el: any) => {
    return { ...el, username: el.username.toLowerCase() };
  });
  let stabilizedThis = arrayLowerCase.map(
    (el: any, index) => [el, index] as [T, number]
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  stabilizedThis = stabilizedThis.map((el: any) => {
    return [array[el[1]], el[1]] as [T, number];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DCDCDD",
  },
}));

interface HeadCell {
  id: keyof IData;
  label: string;
  numeric: boolean;
  width: string | number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "username",
    numeric: false,
    label: "Username",
    width: "18%",
  },

  {
    id: "dataset",
    numeric: false,
    label: "Dataset",
    width: "6%",
  },
  {
    id: "privsString",
    numeric: false,
    label: "Privs",
    width: "auto",
  },
  {
    id: "apiCalls",
    numeric: false,
    label: "API calls",
    width: "6%",
  },
  {
    id: "demo_user",
    numeric: false,
    label: "Demo user",
    width: "4%",
  },
  {
    id: "confirmed",
    numeric: false,
    label: "Confirmed",
    width: "4%",
  },
  {
    id: "last_login",
    numeric: false,
    label: "Last login",
    width: "auto",
  },
  {
    id: "created",
    numeric: false,
    label: "Created",
    width: "8%",
  },
  {
    id: "expires",
    numeric: false,
    label: "Expires",
    width: "8%",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <StyledTableHeaderCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableHeaderCell>
        {headCells.map((headCell) => (
          <StyledTableHeaderCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 700, width: headCell.width }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleSearchRows: (value: string) => void;
  getUsers: () => void;
  setOpenCreateUser: (value: boolean) => void;
  setOpenDeleteUsers: (value: boolean) => void;
  isSuperAdmin?: boolean;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    numSelected,
    handleSearchRows,
    getUsers,
    setOpenCreateUser,
    setOpenDeleteUsers,
    isSuperAdmin,
  } = props;

  return (
    <Fragment>
      <Box
        sx={{
          p: 1.5,
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Box
          sx={{
            display: "flex",

            width: "100%",
            gap: 2,
          }}
        >
          <TextField
            id="search-user-input"
            name="search-user-input"
            autoComplete="off"
            variant="outlined"
            size="small"
            fullWidth
            label={
              <>
                <Search />
                Search
              </>
            }
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handleSearchRows(e.target.value);
            }}
            sx={{ maxWidth: 300 }}
          />
          {isSuperAdmin && (
            <Box
              component="span"
              sx={{ position: "relative", width: 300, zIndex: 4 }}
            >
              <DatasetPicker
                multiple
                sx={{
                  maxWidth: 300,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bgcolor: "#FFF",
                }}
              />
            </Box>
          )}

          {numSelected > 0 && (
            <Typography color="inherit" variant="subtitle1" whiteSpace="nowrap">
              {numSelected} selected
            </Typography>
          )}

          <Box sx={{ display: "flex", ml: "auto", gap: 1 }}>
            <Tooltip title="Add user" sx={{ alignSelf: "flex-end" }}>
              <IconButton
                onClick={() => {
                  setOpenCreateUser(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>

            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    setOpenDeleteUsers(true);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Refresh table">
                <IconButton
                  onClick={() => {
                    getUsers();
                  }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles({
  paginationToolbar: { backgroundColor: "rgba(219, 219, 222,0.2)" },
  paginationLabel: { marginBottom: 0 },
});

// Listify stringified queue data
const formatQueue = (queueStringified: string) => {
  if (!queueStringified) return [];
  try {
    const queueData = JSON.parse(queueStringified);
    return Object.keys(queueData).map((key) => ({
      key,
      value: queueData[key],
    }));
  } catch (err) {
    return [];
  }
};

/* ############################## */

const UserTableComponent = () => {
  const classes = useStyles();
  const { user, superAdminMode, selectedEndpoints }: any = useContext(CTX);

  const userPrivOptions = useMemo(() => {
    if (!user) return [];
    const privs = user.privileges;
    if (privs.includes("ADMIN"))
      return privOptionList.filter(
        (priv) => user.privileges.includes(priv) && priv !== "ADMIN"
      );
    if (privs.includes("USER_ADMIN")) {
      return privOptionList.filter(
        (priv) => user.privileges.includes(priv) && priv !== "USER_ADMIN"
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userApiOptions = useMemo(() => {
    if (!user) return [];
    const privs = user.privileges;
    if (privs.includes("ADMIN")) return apiOptionList;
    else if (privs.includes("USER_ADMIN")) {
      return apiOptionList.filter((api) => user.privileges.includes(api));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IData>("username");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = useRef<Array<number>>([25, 50, 100]);
  const [rows, setRows] = useState<Array<IData>>([]);
  const [rowDetails, setRowDetails] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [open, setOpen] = useState<Array<string>>([]);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openDeleteUsers, setOpenDeleteUsers] = useState(false);

  const [getUsers, { loading, error }] = useLazyQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      datasets:
        selectedEndpoints.length > 0
          ? selectedEndpoints.map((endpoint: any) => endpoint.settingsName)
          : undefined,
    },
    onCompleted: (data) => {
      data = data.getUsers.map((row: any) => {
        // Sortable table data fields
        return {
          ...row,
          confirmed: row.confirmed ? "true" : "false",
          confirmed_at: row.confirmed_at ? row.confirmed_at : "",
          last_login: row.last_login ? row.last_login : "",
          demo_user: row.webdemo ? "true" : "false",
          queue: formatQueue(row.queue),
        };
      });
      const details: Array<any> = [];
      data.forEach((row: any) => {
        details.push({
          username: row.username,
          privs: row.privs,
          docPrivs: row.docPrivs,
          webdemo: row.webdemo,
          queue: row.queue,
          created: row.created,
          last_login: row.last_login,
          confirmed: row.confirmed,
          confirmed_at: row.confirmed_at,
          demo_user: row.demo_user,
        });
      });
      setRows(data);
      setRowDetails(details);
    },
    onError: (error) => {
      setRows([]);
      setRowDetails([]);
    },
  });

  const searchRows = useMemo(() => {
    if (!searchValue) return undefined;
    return rows.filter(
      (row) =>
        row.username.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.privsString.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.dataset.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [rows, searchValue]);

  const visibleRows = useMemo(
    () =>
      stableSort(searchRows ?? rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, searchRows, order, orderBy, page, rowsPerPage]
  );

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.username);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClickCheckbox = (e: React.MouseEvent<unknown>, id: string) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (searchRows ?? rows).length)
      : 0;

  const handleSearchRows = (value: string) => {
    setSearchValue(value);
  };

  const handleCloseDetails = (username: string) => {
    setOpen(open.filter((item) => item !== username));
  };

  const handleDeselectUser = (username: string) => {
    setSelected(selected.filter((item) => item !== username));
  };

  const handleDeleteUser = (username: any) => {
    if (!selected.includes(username)) setSelected([username, ...selected]);
    setOpenDeleteUsers(true);
  };

  const handleCloseCreateUser = () => {
    setOpenCreateUser(false);
  };

  const handleCloseDeleteUsers = () => {
    setOpenDeleteUsers(false);
  };

  useEffect(() => {
    $("#user-account-table")?.scrollTop(0);
  }, [page]);

  useEffect(() => {
    $("#user-account-table")?.scrollTop(0);
    setPage(0);
  }, [selectedEndpoints]);

  return (
    <Fragment>
      <AddUserModal
        open={openCreateUser}
        onClose={handleCloseCreateUser}
        getUsers={getUsers}
        privOptions={userPrivOptions}
        apiOptions={userApiOptions}
      />

      <BulkDeleteModal
        open={openDeleteUsers}
        usernames={selected}
        getUsers={getUsers}
        onClose={handleCloseDeleteUsers}
        onRemove={handleDeselectUser}
        onClear={() => {
          setSelected([]);
        }}
      />

      <Fragment>
        <EnhancedTableToolbar
          numSelected={selected.length}
          getUsers={getUsers}
          handleSearchRows={handleSearchRows}
          setOpenCreateUser={setOpenCreateUser}
          setOpenDeleteUsers={setOpenDeleteUsers}
          isSuperAdmin={superAdminMode}
        />
        <Paper sx={{ mx: 2 }}>
          <TableContainer
            id="user-account-table"
            sx={{
              height: `${useCurrentHeight() - 187}px`,
            }}
          >
            {error ? (
              <Alert
                severity="error"
                variant="filled"
                sx={{ width: "fit-content", m: 2 }}
              >
                {error.message || "Failed to fetch users"}
              </Alert>
            ) : (
              <Box sx={{ position: "relative" }}>
                <LinearProgress
                  color="primary"
                  variant={loading ? "indeterminate" : "determinate"}
                  value={0}
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    visibility: loading ? "visible" : "hidden",
                    zIndex: 3,
                  }}
                />
                <Table
                  aria-labelledby="user-table-title"
                  size="small"
                  stickyHeader
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={visibleRows.length}
                  />
                  <TableBody
                    sx={{
                      overflowX: "hidden",
                      overflowY: "auto",
                      minHeight: "33.3vh",
                    }}
                  >
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.username);
                      const labelId = `user-table-checkbox-${index}`;
                      const userLatestSavedData = rows.find(
                        (user) => user.username === row.username
                      );

                      return (
                        <Fragment key={row.username}>
                          <TableRow
                            hover
                            onClick={() => {
                              if (open.includes(row.username)) {
                                setOpen(
                                  open.filter((item) => item !== row.username)
                                );
                              } else setOpen([...open, row.username]);
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            sx={{
                              cursor: "pointer",
                              bgcolor:
                                index % 2 === 0
                                  ? "initial"
                                  : "rgba(219, 219, 222,0.4)",
                            }}
                          >
                            <TableCell
                              padding="checkbox"
                              onClick={(event) =>
                                handleClickCheckbox(event, row.username)
                              }
                            >
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              id={labelId}
                              sx={{
                                fontWeight: 700,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.username}
                            </TableCell>
                            <TableCell>{row.dataset}</TableCell>
                            <TableCell>{row.privsString}</TableCell>
                            <TableCell>{row.apiCalls}</TableCell>
                            <TableCell>{row.demo_user}</TableCell>
                            <TableCell>{row.confirmed}</TableCell>
                            <TableCell>
                              {row.last_login
                                ? moment(row.last_login).format("L LT")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {row.created
                                ? moment(row.created).format("L")
                                : ""}
                            </TableCell>
                            <TableCell
                              sx={{
                                color:
                                  moment() >
                                  moment(
                                    rowDetails.find(
                                      (detail) =>
                                        row.username === detail.username
                                    )?.webdemo?.expires
                                  )
                                    ? "#EF6F6C"
                                    : "#56E39F",
                              }}
                            >
                              {row.expires
                                ? moment(row.expires).format("L")
                                : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={12} sx={{ py: 0 }}>
                              <Collapse
                                in={open.includes(row.username)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <EditBoxComponent
                                  row={row}
                                  rows={rows}
                                  setRows={setRows}
                                  rowDetails={rowDetails}
                                  setRowDetails={setRowDetails}
                                  userLatestSavedData={userLatestSavedData}
                                  privOptions={userPrivOptions}
                                  apiOptions={userApiOptions}
                                  onClose={handleCloseDetails}
                                  handleDeleteUser={handleDeleteUser}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions.current}
            component="div"
            count={(searchRows ?? rows).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              toolbar: classes.paginationToolbar,
              displayedRows: classes.paginationLabel,
              selectLabel: classes.paginationLabel,
            }}
          />
        </Paper>
      </Fragment>
    </Fragment>
  );
};

export default UserTableComponent;
