import { useState, useEffect } from "react";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
} from "@mui/icons-material";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export const useCurrentWidth = () => {
  let [width, setWidth] = useState(getWidth());
  useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return width;
};

export const useCurrentHeight = () => {
  let [height, setHeight] = useState(getHeight());
  useEffect(() => {
    let timeoutId: any = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setHeight(getHeight()), 150);
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return height;
};

export const getTagIcon = (type: string) => {
  if (typeof type !== "string") return null;
  return type.includes("category") ? (
    <Description fontSize="small" />
  ) : type.includes("topic") ? (
    <Grain fontSize="small" />
  ) : type.includes("person") ? (
    <Person fontSize="small" />
  ) : type.includes("place") ? (
    <LocationOn fontSize="small" />
  ) : type.includes("organisation") ? (
    <AccountBalance fontSize="small" />
  ) : type.includes("event") ? (
    <EventNote fontSize="small" />
  ) : type.includes("object") ? (
    <GridView fontSize="small" />
  ) : null;
};
